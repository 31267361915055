import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import NotFound from "../views/errorPage/ErrorPage.vue"

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home", component: HomeView,
    meta: { title: '首页',id:'home' },
    children: [{
      path: '', component: () => import('../views/PageView.vue'),
    }]
  },
  {
    path: "/product",
    meta: { title: '产品介绍',id:'product' },
    component: HomeView,
    children: [{
      path: '', component: () => import("../views/ProductView.vue"),
    }],
  },
  {
    path: "/news",
    meta: { title: '新闻动态',id:'news' },
    component: HomeView,
    children: [{
      path: '', component: () => import("../views/NewsView.vue"),
    }],
  },
  // 合作案例
  {
    path: "/case",
    meta: { title: '合作案例',id:'case' },
    component: HomeView,
    children: [{
      path: '', component: () => import("../views/CaseView.vue"),
    }],
  },
  // 关于我们
  {
    path: "/about-us",
    meta: { title: '关于灵奇',id:'about-us' },
    component: HomeView,
    children: [{
      path: '', component: () => import("../views/AboutView.vue"),
    }],
  },
  { path: '/:pathMatch(.*)' , component: NotFound},
  { path: "/404",name: "errorPage", component: () => import("../views/errorPage/ErrorPage.vue")},
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.afterEach((to, from) => {
  if (from.name === null && !routes.some(route => route.path === to.path)) {
    return '/404'; // 假设你的404页面路径是/404
  }
});

export default router;
