<template>
  <div id="Home">
    <nav>
      <div class="header-logo">
        <router-link to="/">
          <img src="@/assets/logo-top.png" alt="" />
        </router-link>
      </div>
      <div class="header-nav">
        <div
          :class="[activeNav === item.id ? 'header-nav-act' : '']"
          v-for="item in cateList"
          :key="item.id"
          @click="clickCate(item)"
        >
          <a v-if="item.id === 'Vmap'" :href="item.path" target="_blank">{{
            item.name
          }}</a>
          <template v-else>
            {{ item.name }}
          </template>
        </div>
      </div>
    </nav>
    <div class="router-view">
      <router-view> </router-view>
    </div>
    <div class="footer">
      <div class="ft-top">
        <img class="ft-map" src="@/assets/map.png" alt="" />
        <div class="ft-contact" v-html="companyManage.cooperation"></div>
        <div class="ft-code">
          <img :src="BASE_URL+companyManage.img" alt="" />
          官方微信
        </div>
      </div>
      <div class="ft-copy">
        Copyright ©{{ companyManage.copyright }}
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          >{{ companyManage.recordNumber }}</a
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, watch } from "vue"; // reactive,
import { useRouter } from "vue-router";
import { getCompanyManageData } from "@/api/homeApi";
const BASE_URL = ref(window.BASE_URL);
const companyManage = ref({});
const $router = useRouter();
const activeNav = ref("home");
function getCompanyManageDataFun() {
  getCompanyManageData().then((res) => {
    console.log("公司信息", res);
    if (res.status) {
      companyManage.value = res.data[0];
    }
  });
}
getCompanyManageDataFun();
onBeforeMount(() => {
  // console.log("进来之后", activeNav.value, $router.currentRoute.value.path);
  $router.push(activeNav.value);
});
watch(
  () => $router.currentRoute.value,
  (to) => {
    // console.log("新的路由信息", to);
    activeNav.value = to.meta.id;
    // 这里可以根据不同的路由进行处理逻辑
  }
);
const cateList = ref([]);
cateList.value = [
  {
    name: "首页",
    id: "home",
    path: "/",
  },
  {
    name: "产品介绍",
    id: "product",
    path: "/product",
  },
  {
    name: "新闻动态",
    id: "news",
    path: "/news",
  },
  {
    name: "合作案例",
    id: "case",
    path: "/case",
  },
  {
    name: "开发支持",
    id: "Vmap",
    path: "http://openapi.you07.com/",
  },
  {
    name: "关于灵奇",
    id: "about-us",
    path: "/about-us",
  },
];
const clickCate = (val) => {
  activeNav.value = val.id;
  if (val.id === "Vmap") {
    setTimeout(() => {
      $router.push("/");
      activeNav.value = "home";
    }, 500);
  } else {
    $router.push(val.path);
  }
};
</script>
<style lang="less" scoped>
nav {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 1324px;
  background: #fff;
  display: flex;
  justify-content: space-around;
  z-index: 99;
  .header-logo {
    width: 19%;
    // float: left;
    // margin-top: 10px;
    font-size: 18px;
    // margin-left: 165px;
    line-height: 80px;
    a {
      display: block;
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      img{
        height: 62%;
      }
    }
  }
  .header-nav {
    // margin-right: 165px;
    display: flex;
    div {
      height: 80px;
      line-height: 80px;
      font-size: 18px;
      font-weight: 600;
      color: #333;
      margin-right: 20px;
      padding: 0 10px;
      text-align: center;

      cursor: pointer;
      &:hover {
        color: #4c61d3;
      }
    }
    a {
      color: #333;
      text-decoration: none;
    }
    .header-nav-act {
      color: #4c61d3;
    }
  }
}
.router-view {
  padding-top: 80px;
  min-width: 1324px;
  // min-height: 60vh;
}
.footer {
  width: 100%;
  background: #272727;
  // height: 500px;
  min-width: 1324px;
  padding-bottom: 40px;
  // padding: 50px 0;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  .ft-top {
    // width: 980px;
    overflow: hidden;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .ft-map {
      width: 500px;
      height: 280px;
      margin-top: 50px;
      margin-right: 100px;
    }
    .ft-contact {
      max-width: 400px;
      margin-top: 50px;
      text-align: left;
      color: #fff;
      line-height: 1.4em;
      p {
        width: max-content;
        margin-bottom: 8px;
        color: #fff;
      }
    }
    .ft-code {
      width: 153px;
      height: 196px;
      // background: url(@/assets/erweima.png);
      margin-top: 80px;
      margin-left: 65px;
      // background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      line-height: 346px;
      position: relative;
      img {
        width: 153px;
        height: 153px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  .ft-copy {
    font-size: 16px;
    text-align: center;
    color: #fff;
    margin-top: 40px;
    a {
      color: #fff;
      &:hover {
        color: red;
      }
    }
  }
}
</style>
