import axios from 'axios';
// import '../../public/config.js';
const service = axios.create({
 baseURL: window.BASE_URL,
 timeout: 5000 
});

// 请求拦截器
service.interceptors.request.use(
 config => {
  // 可以在这里添加请求头等信息
  // 例如：config.headers['Authorization'] = 'Bearer your-token';
  return config;
 },
 error => {
  // 请求错误处理
  console.log(error); // for debug
  Promise.reject(error);
 }
);
// 响应拦截器
service.interceptors.response.use(
 response => {
  // 对响应数据做处理，例如只返回data部分
  const res = response.data;
  // 如果返回的状态码为200，说明成功，可以直接返回数据
  if (res.code === 200) {
   return res;
  } else {
   // 其他状态码都当作错误处理
   // 可以在这里对不同的错误码进行不同处理
   return Promise.reject({
    message: res.message || 'Error',
    status: res.code
   });
  }
 },
 error => {
  // 对响应错误做处理
  console.log('err' + error); // for debug
  return Promise.reject(error);
 }
);
function get(url, params = {}, headers) {
 return new Promise((resolve, reject) => {
  service({
   url,
   method: "get",
   params,
   headers: { ...headers },
  })
   .then((response) => {
    resolve(response);
   })
   .catch((error) => {
    reject(error);
   });
 });
}
export function post(url, data) {
 return new Promise((resolve, reject) => {
  service({
   url,
   method: "post",
   data,
  })
   .then((response) => {
    resolve(response);
   })
   .catch((error) => {
    reject(error);
   });
 });
}

export function postUrl(url, data) {
 return new Promise((resolve, reject) => {
  service({
   url,
   method: "post",
   params: data,
   headers: { "Content-Type": "application/x-www-form-urlencoded" },
  })
   .then((response) => {
    resolve(response);
   })
   .catch((error) => {
    reject(error);
   });
 });
}
export default {
 get,
 post,
 postUrl,
 service,
};
