import Request from "../utils/index";
export function getHomeImgUrl(type) {
 return Request.get(`/lqwebsite/imgManage/type/list?type=${type}`);
}
export function getNewsList(type) {
 return Request.get(`/lqwebsite/news/list`,type);
}
export function getNewsType() {
 return Request.get(`/lqwebsite/newsType/getNewsType`);
}
export function getNewsData(id) {
 return Request.get(`/lqwebsite/news/${id}`);
}
export function getQueryList(typeName) {
 return Request.get(`/scene/sceneApplication/query/list/?typeName=${typeName}`);
}
export function getAcademyList() {
 return Request.get(`/lqwebsite/academy/query/list`);
}
export function getProductList() {
 return Request.get(`/lqwebsite/product/query/list`);
}
export function getCaseList() {
 return Request.get(`/lqwebsite/case/query/list`);
}
export function getCompanyManageData() {
 return Request.get(`/lqwebsite/companyManage/query/list`);
}
export function getHonorData() {
 return Request.get(`/lqwebsite/honor/query/list`);
}
// /lqwebsite/recruit/query/list 
export function getRecruitList() {
 return Request.get(`/lqwebsite/recruit/query/list`);
}