<template>
  <router-view />
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  margin: 0px;
  padding: 0px;
  border: 0 none;
  outline: none;
  font-family: "Microsoft Yahei";
  box-sizing: border-box;
  -moz-user-select: none; /*火狐*//*选中文字时避免出现蓝色背景*/   
  -webkit-user-select: none; /*webkit浏览器*//*选中文字时避免出现蓝色背景*/   
  -ms-user-select: none; /*IE10*//*选中文字时避免出现蓝色背景*/
  user-select: none;/*选中文字时避免出现蓝色背景*/
  /* 定义滚动条的宽度、颜色和圆角 */
  ::-webkit-scrollbar {
    width: 0;
  }

  /* 定义滚动条轨道的背景色 */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* 定义滚动条滑块的背景色和圆角 */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* 定义滚动条滑轮按钮的背景色 */
  ::-webkit-scrollbar-button {
    background: #555;
  }
}
img {
  vertical-align: middle;
}
#homeBox,#product,#newsBox,#caseBox,#aboutBox,#aboutUs,
.xinwen,.changjing,.customer-case{
  min-width: 1324px;
  background-color: #f4f6fa;
}
.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.three-lines {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  line-clamp: 2;
  word-wrap: break-word;
  word-break: break-all;
}
.product-content {
  // height: 400px;
  line-height: 1.5em;
  text-align: left;
  padding: 20px 0;
  text-indent: 2em;
  // overflow-x: hidden;
  // overflow-y: auto;
  img {
    max-width: 100%;
  }
}
</style>
